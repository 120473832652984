import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GoArrowRight } from '@react-icons/all-files/go/GoArrowRight'
import Layout from 'src/components/Layout'
import Seo from 'src/components/Seo'
import Article from 'src/components/Article'
// @ts-ignore
import PurchaseImage from 'src/images/purchase.jpg'

const Index: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={t('purchase')} />
      <Article>
        <img src={PurchaseImage} className="pb-16" />
        <section className="pb-16">
          <div
            className="pb-16"
            dangerouslySetInnerHTML={{ __html: t('purchaseInfo') }}
          />
          <h2
            className="text-2xl pb-3"
            dangerouslySetInnerHTML={{ __html: t('purchaseTrialHead') }}
          />
          <div
            className="prose max-w-prose pb-16"
            dangerouslySetInnerHTML={{ __html: t('purchaseTrialBody') || '' }}
          />
          <h2
            className="text-2xl pb-3"
            dangerouslySetInnerHTML={{ __html: t('purchasePinkoiHead') }}
          />
          <div
            className="prose max-w-prose"
            dangerouslySetInnerHTML={{ __html: t('purchasePinkoiBody') || '' }}
          />
          <div className="flex items-center justify-end">
            <GoArrowRight />
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href="https://www.pinkoi.com/product/mvGigPLV"
              dangerouslySetInnerHTML={{ __html: t('purchasePinkoiLinkLabel') }}
            />
          </div>
        </section>
        <section>
          <h2
            className="text-2xl pb-3"
            dangerouslySetInnerHTML={{ __html: t('purchaseBandcampHead') }}
          />
          <div
            className="prose max-w-prose"
            dangerouslySetInnerHTML={{
              __html: t('purchaseBandcampBody') || '',
            }}
          />
          <div className="flex items-center justify-end">
            <GoArrowRight />
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href="https://kyleideta.bandcamp.com/album/gardener"
              dangerouslySetInnerHTML={{
                __html: t('purchaseBandcampLinkLabel'),
              }}
            />
          </div>
        </section>
      </Article>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
